<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="player listmain type-lhc twlh game-twlh game-lhc-sxz">
        <!-- 正肖 -->
        <div class="data c2003">
          <h3>正肖</h3>
          <ul class="custom_ul">
            <li :class="item.selected ? 'selected' : ''" v-for="item in rowsData[0].arr" :key="item.ResultID" @click="setCurrentSelected(item)">
              <ol class="td_name">
                {{
                  item.label
                }}
              </ol>
              <ol class="td_rate">
                {{
                  item.Odds
                }}
              </ol>
              <ol class="td_cash">
                <input type="number" @click.stop="showOrHideCheck($event, item)" v-model="item.money" />
                <div class="quick-check" v-if="item.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(item, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(item)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>
        <!-- 七色波 -->
        <div class="data c2004">
          <h3>七色波</h3>
          <ul>
            <li :class="ele.selected ? 'selected' : ''" v-for="ele in rowsData[1].arr" :key="ele.ResultID" :balls="'sb_'+ele.color">
              <ol class="td_name">
                {{ele.label}}
              </ol>
              <ol class="td_rate">
                {{ele.Odds}}
              </ol>
              <ol class="td_cash">
                <input type="number" @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>

              </ol>
            </li>

          </ul>
        </div>
        <div code="2019" class="data c2019">
          <h3>总肖</h3>
          <ul class="custom_ul1">

            <li :class="ele.selected ? 'selected' : ''" v-for="ele in rowsData[2].arr" :key="ele.ResultID">
              <ol class="td_name">
                {{ele.label}}
              </ol>
              <ol class="td_rate">
                {{ele.Odds}}
              </ol>
              <ol class="td_cash">
                <input type="number" @click.stop="showOrHideCheck($event, ele)" v-model="ele.money" />
                <div class="quick-check" v-if="ele.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(ele, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(ele)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>

          </ul>
        </div>
      </div>
      <yushe />
    </div>

    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <!-- <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div> -->
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yanzheng from "../yanzheng.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { zhengxiaoData } from "../data";
import mixins from "../mixins/mainMixins";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'zxzx',
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [
        { title: "正肖", arr: [] },
        { title: "七色波", arr: [] },
        { title: "总肖", arr: [] },
      ],
      labelArr1: [
        "鼠",
        "牛",
        "虎",
        "兔",
        "龙",
        "蛇",
        "马",
        "羊",
        "猴",
        "鸡",
        "狗",
        "猪",
      ],
      labelArr2: ["红波", "蓝波", "绿波", "和局"],
      labelArr3: ["2肖", "3肖", "4肖", "5肖", "6肖", "7肖", "总肖单", "总肖双"],
      colorArr: ["red", "blue", "green", ""],
    };
  },
  components: {
    topTimer,
    yushe,
    yanzheng,
  },
  watch: {
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title: item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };

                if (item.title == "正肖") {
                  obj.num = ele.label;
                }

                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        // 正肖
        let arr1 = this.filterData(resAArr, 3978, 3989, this.labelArr1);
        this.rowsData[0].arr = arr1;
        // 七色波
        let arr2 = this.ddFilterData1(
          resAArr,
          [3974, 3976, 3975, 3977],
          this.labelArr2
        );
        arr2.forEach((item, index) => {
          item.color = this.colorArr[index];
        });
        this.rowsData[1].arr = arr2;
        // 总肖
        let arr3 = this.filterData(resAArr, 740, 747, this.labelArr3);
        this.rowsData[2].arr = arr3;
        // console.log(this.rowsData, 123456789);
        this.$forceUpdate();
      },
    },
  },
  created() {},

  mounted() {},

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.listmain > .data > ul.custom_ul1 {
  width: 100%;
  height: 52px;

  display: flex;
  flex-direction: column;
}

.listmain > .data ul.custom_ul {
  width: 100%;
  height: 156px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}
.listmain .data ul li {
  width: 25%;
  height: 26px;
  max-height: 26px;
}

.listmain div.c2003 ul li {
  width: 50%;
}
</style>
